<template>
  <div class="view-container">
    <div class="view">
      <div class="view-heading">
        <el-button
            :to="create"
            @click="$router.push('knowledge_base/create')"
            type="primary"
            v-if="ifHasPermission(['knowledge-base-create'])"
        >
          {{ $t('system.create') }}
        </el-button>
      </div>
      <div class="view-body">
        <el-collapse
            accordion
            class="col-md-12"
            v-if="model && ifHasPermission(['knowledge-base-read'])"
        >
          <template v-for="post in model">
            <el-collapse-item
                :name="post.id"
                v-bind:key="post.id"
            >
              <template slot="title">
                <router-link
                    :to="'/knowledge_base/' + post.id"
                    class="wrap-link"
                >{{ post.title }}
                </router-link>
              </template>
              <vue-editor
                  :disabled="true"
                  :editorToolbar="customToolbar"
                  :id="'knowledgeBase' + post.id"
                  v-model="post.description"
              ></vue-editor>
            </el-collapse-item>
          </template>
        </el-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import {VueEditor} from 'vue2-editor';
import notification from '../../notification/notify';

export default {
  name: 'KnowledgeBaseIndex',
  components: {
    VueEditor,
  },
  data() {
    return {
      model: [],
      create: '/knowledge_base/create',
      customToolbar: [],
      collapsed: {},
    };
  },

  beforeMount() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      var vm = this;
      axios.get(`knowledge_base`)
          .then(function (response) {
            Vue.set(vm.$data, 'model', response.data.model);
          })
          .catch(function (error) {
            notification.notify(
                vm.$t('notify.error'),
                error.response.data.error,
                'error');
          });
    },
    collapseTree: function (node) {
      if (this.collapsed[node.id]) {
        Vue.set(this.collapsed, node.id, false);
      } else {
        Vue.set(this.collapsed, node.id, true);
      }
    },
    isCollapsed: function (node) {
      if (this.collapsed[node.id]) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style>
.wrap-link {
  word-wrap: break-word;
}
</style>
